import React, { useEffect, useState } from 'react';

import './App.css';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeOptions, ThemeProvider, createTheme } from '@mui/material/styles';

import { Routes, Route, BrowserRouter, Outlet } from 'react-router-dom';

import Sidebar from './Components/Sidebar';
import {
	Login,
	Home,
	Employees,
	NewEmployee,
	Departments,
	UsersDepartment,
	NewDepartments,
	Requests,
	Gateways,
	NewGateway,
	Profile,
	History,
	Settings,
	QRcodeMobile,
	QRcodeTablet,
	GateProfile,
	Alarms,
} from './Apps/router';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { fetchToken, onMessageListener } from './firebase';

import { useSnackbar } from 'notistack';

import axios from './axiosInstance';
import { API } from './api';

const theme = {
	palette: {
		mode: 'light',
		primary: { main: '#000000' },
		secondary: { main: '#e6e3e3' },
		error: { main: '#DE0030' },
		success: { main: '#1E8D77' },
	},
} as ThemeOptions;

function App() {
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		const accessToken = localStorage.getItem('accessToken');
		if (accessToken)
			onMessageListener((payload: any) => {
				enqueueSnackbar(
					<>
						{payload.notification.title}
						<br />
						{payload.notification.body}
					</>
				);
			});
	}, []);

	useEffect(() => {
		const accessToken = localStorage.getItem('accessToken');
		if (accessToken)
			fetchToken()
				.then((token) => {
					if (token) return axios.post(API.notification.subscribe, { token });
				})
				.then((res) => {})
				.catch((err) => {});
	}, []);

	const accessToken = localStorage.getItem('accessToken');

	return (
		<BrowserRouter>
			<ThemeProvider theme={createTheme(theme)}>
				<CssBaseline enableColorScheme />

				{accessToken ? (
					<Sidebar>
						<div className='App' style={{ overflow: 'auto' }}>
							<Routes>
								<Route index element={<Home />} />
								<Route path='/home' element={<Home />} />
								<Route path='/employees' element={<Outlet />}>
									<Route index element={<Employees />} />
									<Route path='new-employee' element={<NewEmployee />} />
									<Route path='new-employee/:id' element={<NewEmployee />} />
									<Route path='profile/:id' element={<Profile />} />
									<Route path='history/:id' element={<History />} />
								</Route>
								<Route path='/departments' element={<Outlet />}>
									<Route index element={<Departments />} />
									<Route path='new-department' element={<NewDepartments />} />
									<Route path='new-department/:id' element={<NewDepartments />} />
									<Route path=':id' element={<UsersDepartment />} />
								</Route>
								<Route path='/requests' element={<Requests />} />
								<Route path='/alarms' element={<Alarms />} />
								<Route path='/gateways' element={<Outlet />}>
									<Route index element={<Gateways />} />
									<Route path='new-gateway' element={<NewGateway />} />
									<Route path='profile/:id' element={<GateProfile />} />
									<Route path='new-gateway/:id' element={<NewGateway />} />
								</Route>
								<Route path='/settings' element={<Settings />} />
								<Route path='/*' element={<>Not found</>} />
							</Routes>
						</div>
					</Sidebar>
				) : (
					<Routes>
						<Route path='/qrcode-mobile' element={<QRcodeMobile />} />
						<Route path='/qrcode-tablet' element={<QRcodeTablet />} />
						<Route index element={<Login />} />
						<Route path='login' element={<Login />} />
						<Route path='*' element={<>Not found</>} />
					</Routes>
				)}
			</ThemeProvider>
		</BrowserRouter>
	);
}

export default App;
