import React, { useState, useEffect } from 'react';
import {
	Stack,
	Grid,
	Box,
	Typography,
	Button,
	IconButton,
	Menu,
	MenuItem,
	Dialog,
	DialogActions,
	DialogContent,
	Snackbar,
	Skeleton,
	TextField,
	Autocomplete,
	FormControl,
	InputLabel,
	ClickAwayListener,
} from '@mui/material';
import { MenuProps } from '@mui/material/Menu';
import { styled, alpha } from '@mui/material/styles';
import {
	DataGridPremium,
	GridToolbar,
	useGridApiRef,
	GridColDef,
	GridSelectionModel,
	GridToolbarContainer,
	GridToolbarExport,
} from '@mui/x-data-grid-premium';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { Link, useNavigate } from 'react-router-dom';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { LocalizationProvider as LocalizationProviderPro } from '@mui/x-date-pickers-pro';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import axios from '../../axiosInstance';
import { API } from '../../api';
import Report from './Report';
import { generateFlatRows } from '../../lib';
import ConfirmDialog from '../../Components/ConfirmDialog';
import Excel from './Excel';

const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
			},
		},
	},
}));

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
function CustomToolbar() {
	return (
		<GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
			<GridToolbarExport printOptions={{ disableToolbarButton: true }} excelOptions={{ disableToolbarButton: true }} />
		</GridToolbarContainer>
	);
}
type monthsListType = {
	id: string;
	name: string;
	hourly_rate: number;
	total_hours: number;
	amount_earned: number;
	days: {};
}[];
type employeesType = { id: any; name: string; department: any; twd_id: any; hourly_rate: any; days: {}[] }[];
type weeksListType = {
	id: number;
	name: string;
	total_hours: number;
	amount_earned: number;
	days: {};
}[];

type dataReportType = {
	name: string;
	department: string;
	twd_id: string;
	from: string;
	to: string;
	total_hours: number;
	hourly_rate: number;
	amount_earned: number;
	weeks: weeksListType;
	months: monthsListType;
}[];
function Employees() {
	//#region state
	const navigate = useNavigate();
	const [Employees, setEmployees] = useState([]);
	const [totalRecords, settotalRecords] = useState(0);
	const [pageSize, setPageSize] = React.useState<number>(20);
	const [page, setPage] = React.useState(0);
	const [SearchByName, setSearchByName] = useState('');
	const [RowData, setRowData] = useState<any>();
	const [OpenDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [OpenDeactivate, setOpenDeactivate] = useState(false);
	const [OpenActive, setOpenActive] = useState(false);
	const [OpenMessage, setOpenMessage] = useState(false);
	const [IsError, setIsError] = useState(false);
	const [RefreshData, setRefreshData] = useState(false);
	const [Loading, setLoading] = useState(false);
	const [OpenReport, setOpenReport] = useState(false);
	const [ReportDate, setReportDate] = React.useState<DateRange<Date>>([new Date(), new Date()]);
	const [ReportData, setReportData] = useState<dataReportType>([]);
	const [EmployeesInfo, setEmployeesInfo] = useState<employeesType>([]);
	//const [SelectedUsersForReport, setSelectedUsersForReport] = useState<any[]>([]);
	const [DiableReport, setDiableReport] = useState(true);
	const [LoadingReport, setLoadingReport] = useState(false);
	const [EmployeesDDL, setEmployeesDDL] = useState<{}[]>([]);
	const [DepartmentsDDL, setDepartmentsDDL] = useState<{}[]>([]);
	const [SelectedDepartments, setSelectedDepartments] = useState([]);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [anchorElPrint, setAnchorElPrint] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const openPrint = Boolean(anchorElPrint);

	const [OpenExcel, setOpenExcel] = useState(false);
	//const [SelectedUsersForExcel, setSelectedUsersForExcel] = useState<any[]>([]);
	const [ExcelData, setExcelData] = useState<any>([]);
	const [ExcelDate, setExcelDate] = React.useState<DateRange<Date>>([null, null]);
	const [DisableExcel, setDisableExcel] = useState(true);

	const [IsActive, setIsActive] = useState('true');
	const [Type, setType] = useState('Employee');

	const [OpenAutoComplete, setOpenAutoComplete] = useState(false);
	const [DepartmentPage, setDepartmentPage] = useState(0);
	const [DepartmentTotal, setDepartmentTotal] = useState(0);

	const [contextMenu, setContextMenu] = React.useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
	//#endregion state
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setContextMenu(null);
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleClosePrint = () => {
		setAnchorElPrint(null);
	};
	const apiRef = useGridApiRef();
	function getHours(num: number) {
		let hours = Math.trunc(num / 60);
		let mins = num % 60;
		return `${hours}h ${mins}m`;
	}
	//#region useEffect
	useEffect(() => {
		axios
			.get(API.user.basic, {
				params: {
					total: true,
					name: SearchByName.length > 0 ? SearchByName : undefined,
					isActive: IsActive === 'undefined' ? undefined : IsActive === 'true' ? true : false,
					type: Type === 'undefined' ? undefined : Type,
					department: SelectedDepartments.length > 0 ? SelectedDepartments.map((val: any) => val.id) : undefined,
				},
			})
			.then((res) => {
				let employees: { name: any; id: any }[] = res?.data?.data.map((val: any) => ({
					label: `${val?.firstName} ${val?.lastName}`,
					id: val?.id,
				}));
				setEmployeesDDL([...employees]);
			})
			.catch((err) => {
				setEmployeesDDL([]);
			});
	}, [IsActive, Type, SelectedDepartments, SearchByName]);
	useEffect(() => {
		if (OpenAutoComplete) {
			axios
				.get(API.department.department, {
					params: {
						total: true,
						skip: 0,
						limit: 20,
					},
				})
				.then((res) => {
					let departments: { name: any; id: any }[] = res?.data?.data.map((val: any) => ({
						label: val?.name,
						id: val?.id,
					}));
					setDepartmentsDDL([...departments]);
					setDepartmentTotal(res?.data?.totalRecords);
				})
				.catch((err) => {
					setDepartmentsDDL([]);
				});
		} else {
			setDepartmentsDDL([]);
		}
	}, [OpenAutoComplete]);

	useEffect(() => {
		setLoading(true);
		setAnchorEl(null);
		axios
			.get(API.user.user, {
				params: {
					total: true,
					skip: page * (pageSize === undefined ? 0 : pageSize),
					limit: pageSize,
					name: SearchByName.length > 0 ? SearchByName : undefined,
					isActive: IsActive === 'undefined' ? undefined : IsActive === 'true' ? true : false,
					type: Type === 'undefined' ? undefined : Type,
					department: SelectedDepartments.length > 0 ? SelectedDepartments.map((val: any) => val.id) : undefined,
				},
			})
			.then((res) => {
				settotalRecords(res?.data?.totalRecords);

				let data = res?.data?.data.map((val: any) => ({
					id: val?.id,
					name: `${val?.firstName} ${val?.lastName}`,
					img: val?.avatar?.url || '/images/error_img.jpg',
					department: val?.department?.name || '',
					workPhone: val?.workPhone,
					extension: val?.extension,
					workingHours: val?.workingHours ? getHours(val?.workingHours) : '--:--',
					isActive: val?.isActive || false,
					align: 'center',
				}));
				setEmployees(data);
				setLoading(false);
			})
			.catch((err) => {
				setEmployees([]);
				setLoading(false);
			});
	}, [pageSize, page, SearchByName, RefreshData, Type, IsActive, SelectedDepartments]);
	useEffect(() => {
		setEmployeesInfo([]);
		//let array: employeesType = [];
		if (selectionModel.length > 0) {
			selectionModel?.forEach((id: any) => {
				axios
					.get(`${API.user.user}/${id}`)
					.then((res) => {
						let data = res?.data?.data;
						const info = {
							id: data?.id,
							name: `${data?.firstName} ${data?.lastName}`,
							department: data?.departments?.map((val: any) => val.name).join(', ') || '',
							twd_id: data?.tmw_id || '',
							hourly_rate: data.rate ? data.rate : 0,
							days: [],
						};
						setEmployeesInfo((prev) => [...prev, info]);
					})
					.catch((err) => {});
			});
		} else {
			EmployeesDDL?.forEach((element: any) => {
				axios
					.get(`${API.user.user}/${element?.id}`)
					.then((res) => {
						let data = res?.data?.data;
						const info = {
							id: data?.id,
							name: `${data?.firstName} ${data?.lastName}`,
							department: data?.departments?.map((val: any) => val.name).join(', ') || '',
							twd_id: data?.tmw_id || '',
							hourly_rate: data.rate ? data.rate : 0,
							days: [],
						};
						setEmployeesInfo((prev) => [...prev, info]);
					})
					.catch((err) => {});
			});
		}
	}, [selectionModel, EmployeesDDL]);
	useEffect(() => {
		handelReportData(ReportDate);
	}, [ReportDate, EmployeesInfo]);
	useEffect(() => {
		handelExcelData(ExcelDate);
	}, [ExcelDate, EmployeesInfo]);
	//#endregion useEffect
	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'Name',
			align: 'left',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			sortable: false,
			resizable: false,
			disableColumnMenu: true,
			headerClassName: 'table-header left-corner',
			renderCell: (params: any) => {
				return (
					<>
						<Stack direction='row' spacing={2} justifyContent='flex-start' alignItems='center'>
							<img
								src={params?.row?.img}
								width='50px'
								height={'30px'}
								onLoad={() => {}}
								onError={(e) => {
									e.currentTarget.onerror = null; // prevents looping
									e.currentTarget.src = '/images/error_img.jpg';
								}}
							/>
							<Typography variant='subtitle2' gutterBottom sx={{ width: '70%', color: '#000' }}>
								{params?.row?.name}
							</Typography>
						</Stack>
					</>
				);
			},
		},
		{
			field: 'department',
			headerName: 'Department',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			sortable: false,
			resizable: false,
			disableColumnMenu: true,
			headerClassName: 'table-header',
		},
		{
			field: 'workPhone',
			headerName: 'Work Phone',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			sortable: false,
			resizable: false,
			disableColumnMenu: true,
			headerClassName: 'table-header',
		},
		{
			field: 'extension',
			headerName: 'Extension',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			sortable: false,
			resizable: false,
			disableColumnMenu: true,
			headerClassName: 'table-header',
		},
		{
			field: 'workingHours',
			headerName: 'Semi Monthly',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			sortable: false,
			resizable: false,
			disableColumnMenu: true,
			headerClassName: 'table-header',
		},
		{
			field: 'action',
			headerName: 'Actions',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			sortable: false,
			resizable: false,
			disableColumnMenu: true,
			disableExport: true,
			headerClassName: 'table-header right-corner',
			renderCell: (params: any) => (
				<Box>
					<IconButton
						aria-label='action'
						aria-controls={open ? 'basic-menu' : undefined}
						aria-haspopup='true'
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClick}
					>
						<MoreVertIcon />
					</IconButton>
					{RowData?.id === params?.row?.id && (
						<Menu
							id='basic-menu'
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							MenuListProps={{
								'aria-labelledby': 'basic-button',
							}}
						>
							<MenuItem
								onClick={() => {
									//navigate(`/employees/new-employee/${params?.row?.id}`);
									navigate(`/employees/new-employee/${RowData.id}`);
								}}
								sx={{ width: '130px' }}
							>
								<ModeEditIcon fontSize='small' /> &nbsp; Edit
							</MenuItem>
							{params?.row?.isActive === true && (
								<MenuItem onClick={() => setOpenDeactivate(true)} sx={{ width: '130px' }}>
									<PersonOffIcon fontSize='small' /> &nbsp; Deactivate
								</MenuItem>
							)}
							{params?.row?.isActive === false && (
								<MenuItem onClick={() => setOpenActive(true)} sx={{ width: '130px' }}>
									<PersonAddAltIcon fontSize='small' /> &nbsp; Activate
								</MenuItem>
							)}
							{params?.row?.isActive === false && (
								<MenuItem onClick={() => setOpenDeleteDialog(true)} sx={{ width: '130px' }}>
									<DeleteIcon fontSize='small' /> &nbsp; Delete
								</MenuItem>
							)}
						</Menu>
					)}
				</Box>
			),
		},
	];
	//#region actions
	const loadMoreResults = () => {
		const nextDepartmentPage = DepartmentPage + 1;
		setDepartmentPage(nextDepartmentPage);
		const getAnswer = async () => {
			const { data } = await axios.get(API.department.department, {
				params: {
					total: true,
					//skip: nextDepartmentPage * 10,
					skip: 0,
					limit: nextDepartmentPage * 20,
				},
			});
			let departments: { name: any; id: any }[] = data.data.map((val: any) => ({
				label: val?.name,
				id: val?.id,
			}));
			//console.log('nextDepartmentPage', data.data);
			// setDepartmentsDDL([...DepartmentsDDL, ...departments]);
			setDepartmentsDDL([...departments]);
		};
		getAnswer();
	};
	const handleScroll = (event: any) => {
		const listboxNode = event.currentTarget;

		const position = listboxNode.scrollTop + listboxNode.clientHeight;
		if (listboxNode.scrollHeight - position <= 1) {
			if (DepartmentsDDL.length + 1 < DepartmentTotal) {
				setOpenAutoComplete(true);
				setDepartmentsDDL([]);
				loadMoreResults();
			}
		}
	};
	const handleContextMenu = (event: React.MouseEvent) => {
		event.preventDefault();
		setAnchorEl(null);
		//console.log(event.currentTarget.getAttribute('data-id'));
		let rowData = Employees.filter((val: any) => val.id === event.currentTarget.getAttribute('data-id'));
		setRowData(rowData[0]);
		setContextMenu(contextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
	};
	const handelReportData = async (newValue: DateRange<Date>) => {
		let employees: any[] = [];
		setDiableReport(true);
		setLoadingReport(true);
		setReportData([]);

		const from = moment(newValue[0]).startOf('day');
		const to = moment(newValue[1]).endOf('day');
		axios
			.get(API.attendance.attendance, {
				params: {
					employee: selectionModel.length > 0 ? selectionModel : EmployeesInfo?.map((ele: any) => ele?.id),
					from: moment(newValue[0]).toISOString(),
					to: moment(newValue[1]).endOf('day').toISOString(),
					status: 'Accepted',
					limit: 100000,
				},
			})
			.then((res) => {
				let data = res?.data?.data;
				const formateData = async () => {
					try {
						//console.log('data', data);
						employees = [...EmployeesInfo].filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
						let ReportData: any[] = [];
						employees = employees.map((val) => ({
							...val,
							days: data.filter((day: any) => day.employee.id === val.id),
						}));

						employees.forEach((employee: any) => {
							let result = generateFlatRows(from, to, employee.days, 5 * 60, -30);
							let total_hours = 0;
							let groupedResult = result.reduce((acc, cur) => {
								let index = acc.findIndex((val: any) => val.date === cur.date.month() + 1);
								if (index === -1) {
									acc.push({
										date: cur.date.month() + 1,
										data: [
											{ workingHours: 0, data: [] },
											{ workingHours: 0, data: [] },
										],
										workingHours: 0,
									});
									index = acc.length - 1;
								}

								const semiIndex = cur.date.date() <= 15 ? 0 : 1;
								acc[index].data[semiIndex].data.push(cur);

								const addedHours = (cur.workingHours || 0) + (cur.lunchBreak || 0);
								acc[index].data[semiIndex].workingHours += addedHours;
								acc[index].workingHours += addedHours;

								total_hours += addedHours;
								return acc;
							}, []);
							ReportData.push({
								name: employee?.name || '',
								department: employee?.department || '',
								twd_id: employee?.twd_id || '',
								from: moment(newValue[0]).format('DD/MM/YYYY'),
								to: moment(newValue[1]).format('DD/MM/YYYY'),
								total_hours: total_hours ? total_hours : 0,
								hourly_rate: employee.hourly_rate,
								amount_earned: total_hours ? employee.hourly_rate * (total_hours / 60) : 0,
								months: [...groupedResult],
							});
						});
						return { ReportData: [...ReportData] };
					} catch (error) {}
				};
				formateData().then((result: { ReportData: dataReportType } | undefined) => {
					//console.log('result?.ReportData', result?.ReportData);
					setReportData(result?.ReportData || []);
					setTimeout(() => {
						setDiableReport(false);
						setLoadingReport(false);
					}, 300);
				});
			})
			.catch((err) => {});
	};
	const handelExcelData = async (newValue: DateRange<Date>) => {
		//console.log('users', users);
		let employees: any[] = [];
		setDisableExcel(true);
		setExcelData([]);

		const from = moment(newValue[0]).startOf('day');
		const to = moment(newValue[1]).endOf('day');
		axios
			.get(API.attendance.attendance, {
				params: {
					employee: selectionModel.length > 0 ? selectionModel : EmployeesInfo?.map((ele: any) => ele?.id),
					from: moment(newValue[0]).toISOString(),
					to: moment(newValue[1]).endOf('day').toISOString(),
					status: 'Accepted',
					limit: 100000,
				},
			})
			.then((res) => {
				let data = res?.data?.data;
				const formateData = async () => {
					try {
						//console.log('data', data);
						employees = [...EmployeesInfo].filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
						let ExcelData: any[] = [];
						employees = employees.map((val) => ({
							...val,
							days: data.filter((day: any) => day.employee.id === val.id),
						}));
						//console.log('employees', employees);
						employees.forEach((employee: any) => {
							let result = generateFlatRows(from, to, employee.days, 5 * 60, -30);
							let total_hours = 0;
							let groupedResult = result.reduce((acc, cur) => {
								let index = acc.findIndex((val: any) => val.date === cur.date.month() + 1);
								if (index === -1) {
									acc.push({
										date: cur.date.month() + 1,
										data: [
											{ workingHours: 0, data: [] },
											{ workingHours: 0, data: [] },
										],
										workingHours: 0,
									});
									index = acc.length - 1;
								}

								const semiIndex = cur.date.date() <= 15 ? 0 : 1;
								acc[index].data[semiIndex].data.push(cur);

								const addedHours = (cur.workingHours || 0) + (cur.lunchBreak || 0);
								acc[index].data[semiIndex].workingHours += addedHours;
								acc[index].workingHours += addedHours;

								total_hours += addedHours;
								return acc;
							}, []);
							let emptyData = [
								{},
								{},
								{
									name: 'Date',
									department: 'Check In',
									twd_id: 'Check Out',
									from: 'Lunch Break',
									to: 'Total Hours',
									total_hours: '',
									hourly_rate: '',
									amount_earned: '',
									date: null,
									checkIn: null,
									checkOut: null,
									lunchBreak: null,
									workingHours: null,
								},
								{},
							];
							let _groupedResult: any = [];

							groupedResult.forEach((element: any) => {
								element.data.forEach((data: any) => {
									let newData: any = data.data.map((day: any) => ({
										name: `${day?.dayString || '--'}, ${day?.dayDate || ''}`,
										department: `${day?.checkIn || '--:--'}`,
										twd_id: `${day?.checkOut || '--:--'}`,
										from: `${day?.lunchBreak ? day?.lunchBreak + 'm' : '--'}`,
										to: `${getHours((day?.workingHours || 0) + (day?.lunchBreak || 0))}`,
									}));
									if (data.data.length > 0) {
										_groupedResult = [
											..._groupedResult,
											...emptyData,
											...newData,
											{},
											{ name: 'Semi Monthly Total Hours:', department: getHours(data.workingHours) },
										];
									}
								});
								_groupedResult.push({
									name: 'Month Total Hours:',
									department: getHours(element.workingHours),
								});
							});
							//console.log('groupedResult', groupedResult);
							ExcelData.push([
								{
									name: employee?.name || '',
									department: employee?.department || '',
									twd_id: employee?.twd_id || '',
									from: moment(newValue[0]).toLocaleString(),
									to: moment(newValue[1]).toLocaleString(),
									total_hours: total_hours ? getHours(total_hours) : getHours(0),
									hourly_rate: employee.hourly_rate,
									amount_earned: total_hours ? employee.hourly_rate * (total_hours / 60) : 0,
									date: null,
									checkIn: null,
									checkOut: null,
									lunchBreak: null,
									workingHours: null,
								},
								..._groupedResult,
							]);
						});
						return { ExcelData: [...ExcelData] };
					} catch (error) {}
				};
				formateData().then((result: { ExcelData: any } | undefined) => {
					//console.log('result?.ExcelData', result?.ExcelData);
					setExcelData(result?.ExcelData);
					setTimeout(() => {
						setDisableExcel(false);
					}, 400);
				});
			})
			.catch((err) => {});
	};
	const handleChange_Date_Report = (newValue: DateRange<Date>) => {
		//setLoadingReport(true);
		setReportDate(newValue);
	};
	const handleChange_Date_Excel = (newValue: DateRange<Date>) => {
		setExcelDate(newValue);
	};
	const onActivate = () => {
		axios
			.post(`${API.user.user}/${RowData?.id}/activate`)
			.then((res) => {
				setOpenActive(false);
				setIsError(false);
				setOpenMessage(true);
				setRefreshData(!RefreshData);
			})
			.catch((err) => {
				setOpenActive(false);
				setIsError(true);
				setOpenMessage(true);
			});
		setContextMenu(null);
		setAnchorEl(null);
	};
	const onDeactivate = () => {
		axios
			.post(`${API.user.user}/${RowData?.id}/deactivate`)
			.then((res) => {
				setOpenDeactivate(false);
				setIsError(false);
				setOpenMessage(true);
				setRefreshData(!RefreshData);
			})
			.catch((err) => {
				setOpenDeactivate(false);
				setIsError(true);
				setOpenMessage(true);
			});
		setContextMenu(null);
		setAnchorEl(null);
	};
	const onDelete = () => {
		axios
			.delete(`${API.user.user}/${RowData?.id}`)
			.then((res) => {
				setOpenDeleteDialog(false);
				setIsError(false);
				setOpenMessage(true);
				setRefreshData(!RefreshData);
			})
			.catch((err) => {
				setOpenDeleteDialog(false);
				setIsError(true);
				setOpenMessage(true);
			});
		setContextMenu(null);
		setAnchorEl(null);
	};
	//#endregion actions

	return (
		<>
			<Stack spacing={2} p={3} height={1}>
				<Box>
					<Grid container spacing={2}>
						<Grid item xs={1.5}>
							<Typography variant='h5' gutterBottom fontWeight={'600'}>
								Employees
							</Typography>
						</Grid>
						<Grid item xs={3}>
							<input
								id='search-icon'
								style={{ textIndent: '40px', width: '100%', height: '62px' }}
								type='text'
								className='input-style'
								placeholder='Search by Employee'
								value={SearchByName}
								onChange={(e) => {
									setSearchByName(e.target.value);
									setPage(0);
								}}
							/>
						</Grid>
						<Grid item xs={2}>
							<Autocomplete
								disablePortal
								multiple
								options={DepartmentsDDL}
								fullWidth
								sx={{
									'& input': {
										height: '25px',
									},
								}}
								//className='input-style'
								value={SelectedDepartments}
								onChange={(event: any, newValue: any) => {
									setSelectedDepartments(newValue);
									setPage(0);
								}}
								open={OpenAutoComplete}
								onOpen={() => {
									setOpenAutoComplete(true);
								}}
								onClose={() => {
									setOpenAutoComplete(false);
								}}
								loading={OpenAutoComplete && DepartmentsDDL.length === 0}
								ListboxProps={{
									onScroll: handleScroll,
								}}
								autoHighlight
								renderInput={(params) => (
									<TextField
										{...params}
										//id='search-icon'
										style={{ width: '100%' }}
										//type='text'
										//className='input-style'
										label='Search by Department'
									/>
								)}
							/>
						</Grid>
						<Grid item xs={1.5}>
							<FormControl fullWidth>
								<InputLabel id='demo-simple-select-label'>Type</InputLabel>
								<Select
									name='type'
									value={Type}
									onChange={(event: SelectChangeEvent) => {
										setType(event.target.value as string);
										setPage(0);
									}}
									label='Type'
									//className='input-style'
									sx={{
										height: '62px',
										backgroundColor: '#f2f2f2',
										border: '1px solid #f2f2f2 !important',
										borderRadius: '6px',
									}}
								>
									<MenuItem value={'undefined'}>All</MenuItem>
									<MenuItem value={'Employee'}>Employee</MenuItem>
									<MenuItem value={'Admin'}>Admin</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={1.5}>
							<FormControl fullWidth>
								<InputLabel id='demo-simple-select-label'>Active</InputLabel>
								<Select
									name='isActive'
									value={IsActive}
									onChange={(event: SelectChangeEvent) => {
										setIsActive(event.target.value as string);
										setPage(0);
									}}
									label='Status'
									//className='input-style'
									sx={{
										height: '62px',
										backgroundColor: '#f2f2f2',
										border: '1px solid #f2f2f2 !important',
										borderRadius: '6px',
									}}
								>
									<MenuItem value={'undefined'}>All</MenuItem>
									<MenuItem value={'true'}>Active</MenuItem>
									<MenuItem value={'false'}>nonActive</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						{Employees.length > 0 && (
							<Grid item xs={2.5}>
								<Box width={1} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
									<Box>
										<Button
											id='demo-customized-button-options'
											aria-controls={openPrint ? 'demo-customized-menu-options' : undefined}
											aria-haspopup='true'
											aria-expanded={openPrint ? 'true' : undefined}
											variant='contained'
											disableElevation
											onClick={(event: React.MouseEvent<HTMLElement>) => {
												setAnchorElPrint(event.currentTarget);
											}}
											sx={{ textTransform: 'capitalize' }}
											endIcon={<KeyboardArrowDownIcon />}
										>
											Report
										</Button>
										<StyledMenu
											id='demo-customized-menu-options'
											MenuListProps={{
												'aria-labelledby': 'demo-customized-button-options',
											}}
											anchorEl={anchorElPrint}
											open={openPrint}
											onClose={handleClosePrint}
										>
											<MenuItem
												onClick={() => {
													setOpenReport(true);
													//setSelectedUsersForReport([]);
												}}
												disableRipple
											>
												{/* <PictureAsPdfIcon /> */}
												PDF
											</MenuItem>
											<MenuItem onClick={() => setOpenExcel(true)} disableRipple>
												{/* <PictureAsPdfIcon /> */}
												Excel
											</MenuItem>
										</StyledMenu>
									</Box>
									{/* <Button
										onClick={() => {
											setOpenReport(true);
											setSelectedUsersForReport([]);
										}}
										sx={{ textTransform: 'capitalize' }}
										variant='outlined'
									>
										Print
									</Button>{' '} */}
									&nbsp; &nbsp;
									<Link to='/employees/new-employee'>
										<Button sx={{ textTransform: 'capitalize', width: '100%' }} variant='contained'>
											Add New Employee
										</Button>
									</Link>
								</Box>
							</Grid>
						)}
					</Grid>
				</Box>
				{Loading && <Skeleton variant='rectangular' width={'100%'} height={118} />}
				{Employees.length > 0 && !Loading && (
					<Box
						height={1}
						sx={{
							'& .table-header': {
								backgroundColor: '#dadada',
							},
							'& .left-corner': {
								borderTopLeftRadius: '15px',
							},
							'& .right-corner': {
								borderTopRightRadius: '15px',
							},
							'& .css-f3jnds-MuiDataGrid-columnHeaders': {
								borderTopRightRadius: '15px !important',
								borderTopLeftRadius: '15px !important',
								background: '#dadada !important',
							},
						}}
					>
						<DataGridPremium
							className='custom-grid-class'
							//onRowClick={(rowData) => navigate(`/employees/profile/${rowData?.row?.id}`)}
							onRowClick={(rowData) => setRowData(rowData?.row)}
							onCellClick={(rowData) => {
								console.log(rowData);
								if (rowData.field !== 'action' && rowData.field !== '__check__') {
									navigate(`/employees/profile/${rowData?.row?.id}`);
								}
							}}
							//onCellMouseUp={(e) => console.log(e)}

							columns={columns}
							rows={Employees || []}
							apiRef={apiRef}
							disableSelectionOnClick
							pagination
							paginationMode='server'
							page={page}
							onPageChange={(newPage) => setPage(newPage)}
							pageSize={pageSize}
							onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
							rowsPerPageOptions={[5, 10, 20, 50]}
							rowCount={totalRecords}
							disableColumnResize
							disableColumnReorder
							disableChildrenSorting
							componentsProps={{
								row: {
									onContextMenu: handleContextMenu,
									style: { cursor: 'context-menu' },
								},
							}}
							keepNonExistentRowsSelected
							checkboxSelection={true}
							onSelectionModelChange={(newSelectionModel) => {
								console.log('newSelectionModel', newSelectionModel);
								setSelectionModel(newSelectionModel);
							}}
							selectionModel={selectionModel}
							components={{
								Toolbar: CustomToolbar,
							}}
						/>
						<Menu
							open={contextMenu !== null}
							onClose={() => setContextMenu(null)}
							anchorReference='anchorPosition'
							anchorPosition={
								contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
							}
							componentsProps={{
								root: {
									onContextMenu: (e: any) => {
										e.preventDefault();
										setContextMenu(null);
									},
								},
							}}
						>
							<MenuItem
								onClick={() => {
									//navigate(`/employees/new-employee/${params?.row?.id}`);
									navigate(`/employees/new-employee/${RowData.id}`);
								}}
								sx={{ width: '100px' }}
							>
								<ModeEditIcon fontSize='small' /> &nbsp; Edit
							</MenuItem>
							{RowData?.isActive === true && (
								<MenuItem onClick={() => setOpenDeactivate(true)} sx={{ width: '130px' }}>
									<PersonOffIcon fontSize='small' /> &nbsp; Deactivate
								</MenuItem>
							)}
							{RowData?.isActive === false && (
								<MenuItem onClick={() => setOpenActive(true)} sx={{ width: '130px' }}>
									<PersonAddAltIcon fontSize='small' /> &nbsp; Activate
								</MenuItem>
							)}
							{RowData?.isActive === false && (
								<MenuItem onClick={() => setOpenDeleteDialog(true)} sx={{ width: '130px' }}>
									<DeleteIcon fontSize='small' /> &nbsp; Delete
								</MenuItem>
							)}
						</Menu>
					</Box>
				)}
				{Employees.length === 0 && !Loading && (
					<Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
						<Box component='img' sx={{ width: '30%', display: 'block' }} alt='/' src='/images/employees.svg' />
						<Typography variant='h5' gutterBottom fontWeight={'500'}>
							No Employees Yet!
						</Typography>
						<Typography variant='subtitle2' display='block' gutterBottom sx={{ color: '#707070' }}>
							You not have any employees there You can add new employee from below.
						</Typography>
						<Link to='/employees/new-employee'>
							<Button sx={{ textTransform: 'capitalize', width: '100%' }} variant='contained'>
								Add New Employee
							</Button>
						</Link>
					</Stack>
				)}
			</Stack>
			{/** Activate */}
			<ConfirmDialog
				open={OpenActive}
				onClose={() => setOpenActive(false)}
				title=''
				message='Are you sure you want to Activate this employee?'
				cancelLabel='Close'
				saveLabel='Activate'
				onApply={onActivate}
			/>
			{/** Deactivate */}
			<ConfirmDialog
				open={OpenDeactivate}
				onClose={() => setOpenDeactivate(false)}
				title=''
				message='Are you sure you want to Deactivate this employee?'
				cancelLabel='Close'
				saveLabel='Deactivate'
				onApply={onDeactivate}
			/>
			{/** Delete */}
			<ConfirmDialog
				open={OpenDeleteDialog}
				onClose={() => setOpenDeleteDialog(false)}
				title=''
				message='Are you sure you want to delete this employee?'
				cancelLabel='Close'
				saveLabel='Delete'
				onApply={onDelete}
			/>

			{/** Report Dialog */}
			<Dialog
				open={OpenReport}
				onClose={() => setOpenReport(false)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogContent>
					{/* <Typography variant='subtitle1' gutterBottom component='div'>
						Select Employees:
					</Typography>
					<Autocomplete
						disablePortal
						multiple
						//ListboxComponent={() => <div>xxx</div>}
						//PaperComponent={() => <Box maxHeight={'20px'}></Box>}
						id='combo-box-demo'
						options={[...EmployeesDDL]}
						sx={{ width: '100%' }}
						value={SelectedUsersForReport}
						onChange={(event: any, newValue: any) => {
							setLoadingReport(true);
							//console.log(newValue);
							if (newValue.find((ele: any) => ele.id === 'All')) {
								let value: any[] = EmployeesDDL.map((val: any) => val).filter((ele) => ele.id !== 'All');
								setSelectedUsersForReport([...value]);
							} else {
								setSelectedUsersForReport(newValue);
							}

							//handelReportData(ReportDate, newValue);
						}}
						renderInput={(params) => {
							//console.log(params);
							return (
								<TextField
									{...params}
									//id='search-icon'
									style={{ width: '100%', minHeight: '50px' }}
								/>
							);
						}}
					/> */}

					<Typography variant='subtitle1' gutterBottom component='div'>
						Select Month:
					</Typography>
					<LocalizationProviderPro dateAdapter={AdapterDateFns} localeText={{ start: 'From', end: 'To' }}>
						<DateRangePicker
							value={ReportDate}
							onChange={(newValue: DateRange<Date>) => handleChange_Date_Report(newValue)}
							renderInput={(startProps, endProps) => (
								<React.Fragment>
									<TextField className='input-style' {...startProps} />
									<Box sx={{ mx: 2 }}> to </Box>
									<TextField className='input-style' {...endProps} />
								</React.Fragment>
							)}
						/>
					</LocalizationProviderPro>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center' }}>
					<Button
						variant='outlined'
						sx={{ textTransform: 'capitalize', width: '160px' }}
						onClick={() => {
							setOpenReport(false);
							//setSelectedUsersForReport([]);
							setReportDate([null, null]);
							setReportData([]);
							handleClosePrint();
						}}
					>
						Cancel
					</Button>
					<Report
						ReportData={ReportData}
						DiableReport={DiableReport}
						setDiableReport={setDiableReport}
						LoadingReport={LoadingReport}
						setLoadingReport={setLoadingReport}
					/>
				</DialogActions>
			</Dialog>
			{/** Excel  */}
			<Dialog
				open={OpenExcel}
				onClose={() => setOpenExcel(false)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogContent>
					{/* <Typography variant='subtitle1' gutterBottom component='div'>
						Select Employees:
					</Typography>
					<Autocomplete
						disablePortal
						multiple
						id='combo-box-demo'
						options={EmployeesDDL}
						sx={{ width: '100%' }}
						value={SelectedUsersForExcel}
						onChange={(event: any, newValue: any) => {
							//console.log(newValue);
							if (newValue.find((ele: any) => ele.id === 'All')) {
								let value: any[] = EmployeesDDL.map((val: any) => val).filter((ele) => ele.id !== 'All');
								setSelectedUsersForExcel([...value]);
							} else {
								setSelectedUsersForExcel(newValue);
							}

							//handelReportData(ReportDate, newValue);
						}}
						renderInput={(params) => {
							//console.log(params);
							return (
								<TextField
									{...params}
									//id='search-icon'
									style={{ width: '100%', minHeight: '50px' }}
								/>
							);
						}}
					/> */}
					<Typography variant='subtitle1' gutterBottom component='div'>
						Select Month:
					</Typography>
					<LocalizationProviderPro dateAdapter={AdapterDateFns} localeText={{ start: 'From', end: 'To' }}>
						<DateRangePicker
							value={ExcelDate}
							onChange={(newValue: DateRange<Date>) => handleChange_Date_Excel(newValue)}
							renderInput={(startProps, endProps) => (
								<React.Fragment>
									<TextField className='input-style' {...startProps} />
									<Box sx={{ mx: 2 }}> to </Box>
									<TextField className='input-style' {...endProps} />
								</React.Fragment>
							)}
						/>
					</LocalizationProviderPro>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center' }}>
					<Button
						variant='outlined'
						sx={{ textTransform: 'capitalize', margin: '5px' }}
						onClick={() => {
							setOpenExcel(false);
							//setSelectedUsersForExcel([]);
							setExcelData([]);
							setExcelDate([null, null]);
						}}
					>
						Cancel
					</Button>
					<Excel ExcelData={ExcelData} DisableExcel={DisableExcel} />
				</DialogActions>
			</Dialog>
			<Snackbar open={OpenMessage} autoHideDuration={6000} onClose={() => setOpenMessage(false)}>
				{IsError ? (
					<Alert onClose={() => setOpenMessage(false)} severity='error' sx={{ width: '100%' }}>
						Request failed!
					</Alert>
				) : (
					<Alert onClose={() => setOpenMessage(false)} severity='success' sx={{ width: '100%' }}>
						Request succeeded.
					</Alert>
				)}
			</Snackbar>
		</>
	);
}

export default Employees;
